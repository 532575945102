/* look like native */
body {
  // -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
html {
  overscroll-behavior: none;
  overflow-y: scroll; // 始终预留滚动条空间
}
.word-app-root{
  background-color: #e8e8e8;;
}
.sideBar {
  width: 220px; // 210px +  overflow 10px
  flex-shrink: 0;
}
.fixedContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 1100;
  position: fixed;
  left: 58px;
  top: 0;
  bottom: 0;
  width: 210px;
  box-shadow: 0 0 4px #0000002b;
}

.mainContainer {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.center {
  display: flex;
  margin: 0 0 30px;
  max-width: 100%;
  justify-content: center;
}

main {
  width: 760px;
  margin: 0 12px;
}

aside {
  margin: 0 10px;
  width: 200px;
  flex-shrink: 0;
}

@media screen and (max-width: 1100px) {
  aside {
    display: none;
  }
}