.header {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.panelHeader {
  align-self: flex-start;
  margin: 10px auto 10px 16px;
  line-height: 22px;

  font-size: 16px;
  font-weight: bold;
  color: #333;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// hover to show
.hoverable {
  background-color: #f3f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  .backBtn {
    margin-left: 3px;
    margin-right: -12px;
  }
  .listItem {
    padding-left: 16px;
    padding-right: 8px;
    span {
      font-weight: bold;
      color: #444;

      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      display: block;
    }
    .iconRight {
      opacity: 0.2;
      transition: 0.1s ease-out opacity;
    }
    &:hover .iconRight {
      opacity: 0.8;
      transition: 0.15s ease-in opacity;
    }
  }
  .hoverShow {
    opacity: 0;
    // background-color: #f2f2f2;
    transition: 0.15s opacity;
  }
  &:hover .hoverShow {
    opacity: 1;
  }
}

.card.card {
  background: white;
  border-radius: 18px 18px 0 0;
  margin: 0 -10px 0 8px;
  box-shadow: 1px 2px 12px -2px #aec9d0;
  height: 100%;
}
