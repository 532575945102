// 针对原始内容样式的修改
.entry_content.entry_content.entry_content {
  max-width: 760px;
  margin: 6px auto;

  // link
  .wordfams .crossRef {
    border-bottom: 0.5px solid #d8d8d8;
  }
  .dictionary .defRef {
    border-bottom: 0.5px solid #d8d8d8;
  }

  .frequent .LEVEL{
    font-family: monospace;
  }

  // audio
  [data-src-mp3] {
    cursor: pointer;
  }

  .Sense {
    margin: 10px 0 10px;
    padding: 20px;
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: 1px solid #f0f0f0;
  }

  .DEF {
    font-weight: bold;
    color: #444;
  }
  // 突兀的蓝色文字
  .GLOSS,
  .GEO,
  .RELATEDWD,
  .Crossrefto,
  .LINKWORD {
    color: #888;
  }
  .PROPFORMPREP,
  .PROPFORM,
  .ColloExa > .COLLO {
    margin-left: 2em;
  }
  span.EXAMPLE {
    color: #444;
    font-family: Georgia;
    padding-left: 2em;
    margin-left: 0px;
    line-height: 1.3em;
    position: relative;
    margin-top: 0.3em;
    span[data-src-mp3] {
      position: absolute;
      left: 0;
      top: -0.45em;
    }
  }
  .sensenum + img {
    max-width: 32px;
    min-height: 32px;
  }

  //   不需要的内容

  // .dictionary_intro {
  //   display: none;
  // }
  // .topics_container,
  // .etym,
  // .asset.div > .yellow_box,
  // .assetlink {
  //   display: none;
  // }

  // h1.pagetitle,
  // .wordfams {
  //   display: none;
  // }

  .dictionary > .dictentry-card {
    box-shadow: 0 0.5px 3px #e0e0e0;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;

    .dictentry {
      padding: 16px 30px;
      background: white;
    }
  }
}

//blur
.word-entry-container {
  overflow: hidden;

  &.blured {
    .dictentry {
      filter: brightness(0.99);
    }
    .blur.blur-response:hover {
      transition: filter 0.2s;
      filter: blur(2px);
    }
    .blur {
      filter: blur(3.5px);
      pointer-events: none;
      user-select: none; //只有清晰元素可选择
      opacity: 0.3;
      &.blur-response {
        opacity: 1;
        pointer-events: auto;
        cursor: pointer;
        & * {
          pointer-events: none;
        }
        &.show {
          filter: none;
          user-select: auto;
          cursor: auto;
          & * {
            pointer-events: auto;
          }
        }
      }
    }
  }
}

.DEF,
.EXAMPLE,
.Hint,
.COLLO,
.Crossref,
.PROPFORM,
.COLLOC,
.PROPFORMPREP,
.ColloBox,
.GramBox,
.ThesBox {
  // filter: blur(0.8px);
}


// replace font-awesome
.fa.fa-volume-up {
  background-color: currentColor;
  mask-image: url('./baseline-volume_up-24px.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  &:after{
    content: 'ai'; // 撑开合适的尺寸
    visibility: hidden;
  }
}


// loading
.word-entry-container .center-by-parent {
  $size: 120px;
  position: absolute;
  width: $size;
  left: 0;
  right: 0;
  margin: auto;
  .loading-card {
    width: $size;
    height: $size;
    position: fixed;
    // left: 0;
    // right: 0;
    margin: auto;
    top: 200px;
    z-index: 1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
    background-color: #d8d8d873;
    box-shadow: 0 8px 30px #f7f7f7b3;
    @supports not (backdrop-filter: none) {
      background-color: #eaeaeaf4;
    }
  }
}

.word-entry-container .notfound-card {
  box-shadow: 0 0.5px 3px #e0e0e0;
  border-radius: 10px;
  background-color: white;

  // height: 400px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  p {
    color: #666;
  }
}
