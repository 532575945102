.Paper {
    width: 500px;
    height: 500px;
}

.invalid {
    :global(.MuiDialog-paper) {
        animation: shake 0.5s ease-in-out;
        will-change: transform;
        :global(.MuiDialogContent-root),
        :global(.MuiDialogActions-root) {
            pointer-events: none; // animation performance
        }
    }
}


@keyframes shake {
    0% {
        transform: translateX(0);
    }
    8% {
        transform: translateX(-10px);
    }
    25% {
        transform: translateX(10px);
    }
    41% {
        transform: translateX(-8px);
    }

    58% {
        transform: translateX(8px);
    }
    75% {
        transform: translateX(-5px);
    }
    92% {
        transform: translateX(3px);
    }
    100% {
        transform: translateX(0);
    }
}
