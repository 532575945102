.ActivityBar {
  a {
    text-decoration: none;
    display: block;
    padding: 1px 2px;
  }
  flex-shrink: 0;

  .collapse {
    background-color: #f3f9f9;
    box-sizing: border-box;
    width: 58px;
    overflow: hidden;
    white-space: nowrap;
  }

  .labeledIcon {
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #777;
    h6 {
      // line-height: 1.1;
      font-size: 10px;
      margin-bottom: -3px;
    }
  }

  .position {
    top: 0;
    bottom: 0;
    height: auto;
  }
  .listEnd {
    margin-top: auto;
  }

  :global {
    .MuiDrawer-paperAnchorDockedLeft {
      border-right-color: #a0a0a01f;
    }
  }

  :global {
    .MuiListItem-root {
      color: rgba(0, 0, 0, 0.87);
      border-radius: 12px;
    }
  }

  :global .selected .MuiListItem-root {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
