.ReviewOption :global {
  background-color: #fff;

  box-shadow: 0 0.5px 3px #e0e0e0;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 6px;
  position: relative;
  overflow: hidden;
  z-index: 900; // animation performance

  // @mixin transTransform {
  //   transition: transform 0.3s ease-out;
  // }

  .main:nth-child(2) {
    // 让两组重叠
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .main:nth-child(3) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: none;
  }
  .main {
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    width: 100%;
    box-sizing: border-box;

    h4 {
      margin: 0;
      margin-right: auto;
      font-size: 18px;
    }
    .secondary {
      margin-right: auto;
      font-size: 13px;
      color: #aaa;
    }
  }
  .buttons {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;

    display: flex;
    align-items: center;
    button.MuiButtonBase-root {
      width: 100px;
      margin-right: 10px;
    }

    button.feedback-good:hover ~ .tip-good {
      display: inline;
    }
    button.feedback-bad:hover ~ .tip-bad {
      display: inline;
    }
    .tip-good,
    .tip-bad {
      display: none;
      color: #bbb;
      font-size: 13px;
      order: -1;
      margin-right: 13px;
    }
  }
}

// tranisition

@mixin at-center {
  transform: translateX(50%);
  h4,
  .secondary {
    transform: translateX(-50%);
  }
}

@mixin at-left {
  transform: none;
  h4,
  .secondary {
    transform: none;
  }
}

@mixin at-tip {
  transform: translateX(-50%);
  h4,
  .secondary {
    transform: none;
  }
}
@mixin at-origin {
  opacity: 0.2;
  transform: translateX(80%);
  h4,
  .secondary {
    transform: translateX(-100%);
  }
}

// tranisitions
.ReviewOption :global {
  .main {
    will-change: opacity, transform;
    transition: transform 0.3s ease-out, opacity 0.3s;
    h4,
    .secondary {
      will-change: transform;
      transition: transform 0.3s ease-out;
    }
  }
  .buttons {
    will-change: transform opacity;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in;
  }
}

.ReviewOption:global(.s-recall) :global {
  .main:first-child {
    @include at-center;
  }
  .main:nth-child(2) {
    @include at-center;
    opacity: 0.001;
  }
  .buttons.b1 {
    opacity: 1;
    transform: none;
    transition-delay: 0.2s;
  }
  .buttons.b2 {
    pointer-events: none;
    button {
      opacity: 0;
    }
    opacity: 0;
    // visibility: hidden;
    transform: translateX(200px);
  }
}

.ReviewOption:global(.s-feedback) :global {
  .main:first-child {
    @include at-left;
    opacity: 0.001;
  }
  .main:nth-child(2) {
    @include at-left;
    opacity: 1;
  }

  .buttons.b1 {
    opacity: 0;
    transform: translateX(-200px);
  }
  .buttons.b2 {
    transition-delay: 0.1s;
    opacity: 1;
    transform: none;
  }
}

.ReviewOption:global(.s-done_good) :global,
.ReviewOption:global(.s-done_bad) :global {
  .main {
    @include at-tip;
    opacity: 0;
  }
  .buttons button {
    opacity: 0;
    pointer-events: none;
  }
  .buttons.b2 {
    .tip-active {
      display: block;
      color: #666;
      transform: scale(1.3);
      transform-origin: 150% center;
      transition: transform 0.4s cubic-bezier(0.15, 0.7, 0.2, 1), color 0.5s;
    }
  }
}

.ReviewOption:global(.s-origin) :global {
  .main {
    @include at-origin;
    opacity: 0;
    transition: none;
  }
  .main {
    h4,
    .secondary {
      transition: none;
    }
  }
  .buttons.b1 {
    opacity: 0;
    transform: translateX(50px);
    transition: none;
  }
  .tip-active {
    display: block;
    color: #666;
    transform: scale(1.3);
    transform-origin: 150% center;
    transition: transform 0.4s cubic-bezier(0.15, 0.7, 0.2, 1), color 0.5s;
  }
  .buttons button {
    transition: none;
    opacity: 0;
  }
}

.ReviewOption:global(.s-all_done) :global {
  .main {
    opacity: 0;
    pointer-events: none;
  }
  .buttons {
    display: none;
  }
  .main:nth-child(3) {
    display: flex;
    opacity: 1;
    align-items: center;
    justify-content: center;
    h4 {
      margin-right: 0;
    }
  }
}
