
.header {
    display: flex;
    align-items: center;
    padding-right: 8px;
}
.panelHeader {
    align-self: flex-start;
    margin: 10px auto 10px 22px;
    font-size: 16px;
    font-weight: bold;
}

// hover to show
.hoverable {
    background-color: #f3f9f9;
    display: flex;
    flex-direction: column;
    height: 100%;
    .hoverShow {
        opacity: 0;
        transition: 0.15s opacity;
    }
    &:hover .hoverShow {
        opacity: 1;
    }
}
