.header {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.panelHeader {
  align-self: flex-start;
  margin: 10px auto 10px 22px;
  font-size: 16px;
  font-weight: bold;
}

// hover to show
.hoverable {
  background-color: #f3f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  .hoverShow {
    opacity: 0;
    // background-color: #f2f2f2;
    transition: 0.15s opacity;
  }
  &:hover .hoverShow {
    opacity: 1;
  }
}

.hoverable :global .remains {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999;
  margin: -4px 0px 8px 7px;
  height: 24px;
  .green-dot,
  .red-dot {
    margin-right: 2px;
    font-size: 20px;
    user-select: none;
  }
  .red-dot {
    color: #ff000e;
  }
  .green-dot {
    color: green;
  }

  strong {
    color: #777;
    margin: 0 4px;
  }
}


// // tranisition

// @mixin trans($name) {
//   .#{$name}-appear,
//   .#{$name}-enter {
//     // opacity: 0;
//     transform: translateY(12px);

//     transition-duration: 400ms, 500ms;
//     transition-property: opacity, transform;
//     transition-timing-function: ease-in, cubic-bezier(0.15, 0.7, 0.2, 1);
//   }
//   .#{$name}-appear-active,
//   .#{$name}-enter-active {
//     transform: translateX(0px);
//     opacity: 1;
//   }
// }

// :global {
//   @include trans(list-trans);
// }