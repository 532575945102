.rootContainer {
  height: 100%;
  overflow: hidden;
  position: relative;

  background-color: #fff;
  padding-top: 8px;
  border-radius: 18px 18px 0 0;
  margin-left: 8px;
  margin-right: -10px;
  box-shadow: 1px 2px 12px -2px hsla(193, 27%, 75%, 1);
  box-shadow: 1px 2px 3px 0px hsla(193, 27%, 75%, 1);
  box-shadow: 1px 2px 12px -2px hsla(193, 27%, 75%, 1), 1px 2px 12px -2px hsla(193, 27%, 75%, 1);
  transition: box-shadow 0.3s;

  // & {
  //   transform: rotate(3deg) translateY(20px);
  //   transform-origin: center 160%;
  //   transition: transform cubic-bezier(0.15, 0.7, 0.2, 1) 0.5s;
    
  // }






  &:hover {
    box-shadow: 1px 2px 16px -2px hsla(193, 27%, 75%, 1);
  }

  .locate {
    &:global(.MuiFab-root) {
      width: 52px;
      height: 28px;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      // color: white;
      // background-color: hsla(194, 78%, 40%, 1);
      // &:hover {
      //   background-color: hsla(194, 78%, 40%, 1);
      // }
    }
    z-index: 1;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 16px;

    transition: opacity 0.25s ease-in,
      transform 0.6s cubic-bezier(0.15, 0.7, 0.2, 1);
    &.locateUp {
      top: 24px;
      &.hide {
        transform: translateY(30px) scale(0.8);
      }
    }
    &.locateDown {
      bottom: 12px;
    }
    &.hide {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s, transform 0.2s ease-in;
      transform: translateY(-30px) scale(0.8);
    }
  }

  @media (hover: hover) {
    &:hover .outline {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

ul.outline {
  overscroll-behavior: none;
  @media (hover: hover) {
    opacity: 0;
    pointer-events: none;
  }

  max-height: calc(30% + 150px);
  position: absolute;
  right: 0;
  top: 48%;
  margin: 0;
  margin-right: 6px;
  transform: translateY(-50%);
  z-index: 1000;
  overflow: scroll;
  padding: 10px 0px;

  color: #b9b9b97d;
  border-radius: 12px;
  transition: color 0.15s, background-color 0.2s;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover {
    background-color: #f4f4f4;
    color: #777;
    box-shadow: 0 0 2px #7979794f;
    .now {
      color: #333;
    }
  }
  li {
    list-style: none;
    font-size: 12px;
    text-align: center;
    height: 24px;
    width: 22px;
    line-height: 24px;
    text-align: center;
    padding-right: 2px;
    border-left: 2px solid transparent;

    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    &.now {
      font-weight: bold;
      color: #999;
      // border-left-color: currentColor;

      &::after {
        position: absolute;
        height: 14px;
        width: 2px;
        left: -2px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: currentColor;
        content: '';
      }
    }
    &:hover {
      color: #333;
      background-color: #cccccc80;
    }
  }
}

.WordList {
  overscroll-behavior: none;
  -webkit-overscroll-behavior: none;
  overflow-y: scroll !important; // 始终预留滚动条空间
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }

  @media (hover: hover) {
    &:hover::-webkit-scrollbar-thumb {
      background: hsla(187, 70%, 38%, 0.1);
    }
  }
}

.WordList {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #fff;

  // as a child
  height: 100%;
  width: 100%;

  // scroll shadow
  // box-shadow: inset 0px 16px 10px -16px #737373,
  //     inset 0px -16px 10px -16px #737373;

  :global(h4.sticky-header) {
    color: #888;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    margin: 0;
    padding-left: 22px;
    background-color: white;
    position: sticky;
    top: 0;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.stickyActive {
      border-bottom: 1px #f8f8f8 solid;
    }
  }

  .section-day {
    padding: 0;
    margin: 0;
    padding-bottom: 18px;
  }
}

.emptyContainer {
  position: absolute;
  left: 12px;
  right: 12px;
  top: 40%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #bbb;
  font-size: 14px;
}
