// todo: not global
// tranisition
$duration: 200ms;
@mixin trans($name) {
  .#{$name}-appear,
  .#{$name}-enter {
    opacity: 0;
    transform: translateX(12px);
    transition: opacity 400ms ease-in, transform 500ms  cubic-bezier(0.15, 0.7, 0.2, 1);
    // transition-duration: 400ms, 500ms;
    // transition-property: opacity, transform;
    // transition-timing-function: ease-in, cubic-bezier(0.15, 0.7, 0.2, 1);
  }
  .#{$name}-appear-active,
  .#{$name}-enter-active {
    transform: translateX(0px);
    opacity: 1;
  }
}

:global {
  @include trans(header-trans);
}
