// tranisition
.MainContent {
  $duration: 200ms;

  @mixin trans($name, $height, $margin) {
    .#{$name}-enter {
      max-height: 0px;
      opacity: 0;
      margin-bottom: 0;
      transition: $duration max-height, $duration opacity,
        $duration margin-bottom;
    }
    .#{$name}-enter-active {
      opacity: 1;
      margin-bottom: $margin;
      max-height: $height;
    }

    .#{$name}-exit {
      max-height: $height;
      opacity: 1;
      margin-bottom: $margin;
      transition: $duration max-height, $duration opacity,
        $duration margin-bottom;
    }
    .#{$name}-exit-active {
      opacity: 0;
      margin-bottom: 0;
      max-height: 0px;
    }
  }
  
  :global {
    @include trans(goreviewbar-trans, 48px, 12px);
    @include trans(ReviewOption-trans, 70px, 6px);
  }
}

.GoReviewBar {
  background-color: #f0fdfd;

  box-shadow: 0 0.5px 3px #e0e0e0;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 12px;
  position: relative;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  color: #888;
  cursor: pointer;
  user-select: none;

  color: #0097a7;
  animation: bgc 1.5s ease-out 1s infinite alternate;

  &:hover {
    background-color: #faffff;
    animation: none;
  }
  &:active {
    background-color: #f8fdfd;
    animation: none;
  }

  @keyframes bgc {
    from {
      background-color: #f0fdfd;
    }
    to {
      background-color: white;
    }
  }

  svg {
    margin-right: 4px;
  }
}
