.search {
  display: inline-flex;
  position: relative;
  border-radius: 999px;
  background-color: #0000000b;
  transition: background-color 0.1s;
  &:hover {
    background-color: #0000000f;
  }

  margin: 0;
  color: #00000070;

  .searchIcon {
    width: 50px;
    box-sizing: border-box;
    padding-left: 6px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inputRoot {
    color: inherit;
  }

  .inputInput {
    width: 84px;
    padding: 8px 16px 8px 50px;
    transition: width 0.2s;
    color: #00000050;

    &:focus {
      width: 130px;
      color: #000000A2;
    }
    
  }
}
