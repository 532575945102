.toolbarPh {
  height: 48px;
  width: 100%;
  margin-bottom: 24px;
  :global {
    .toolbar {
      -webkit-app-region: drag;
      // & * {
      //   -webkit-app-region: none;
      // }
      // -webkit-user-select: none;
      // min-height: 48px;
      left: 268px;
      right: 0;
      position: fixed;
      z-index: 1000;

      background-color: white;
      box-shadow: 0 0 3px #0000002b;
      h2 {
        padding-left: 8px;
        margin: 0;
        margin-right: 4px;
        font-size: 26px;
        color: #333;

        // long words
        max-width: 210px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &.smaller {
          font-size: 20px;
        }
      }
      .addBtn {
        margin-bottom: -4px;
        padding: 6px;
        svg {
          font-size: 20px;
        }
      }

      button.switch {
        background-color: transparent;
        
        padding: 6px;
        svg {
          transition: transform 0.2s, opacity 0.2s;
        }
        svg:first-child {
          color: green;
          opacity: 1;
          transition-delay: 0.2s;
        }
        svg + svg {
          position: absolute;
          opacity: 0;
          transform: scale(0.2);
          // transition-delay: 0.5s;
        }
        
        &.canHover:hover {
          svg:first-child {
            opacity: 0;
            transform: scale(0.2);
            transition-delay: 0s;
          }
          svg + svg {
            transition-delay: 0.1s;
            opacity: 1;
            transform: none;
          }
        }
      }

      span.learned-times {
        font-size: 12px;
        padding: 12px;
        color: #aaa;
        align-self: flex-end;
      }
      .listNames {
        font-size: 11px;
        color: #aaa;
        align-self: flex-end;
        margin-left: 4px;
        margin-bottom: 14px;
        opacity: 0;
        opacity: 1;
        transition: opacity 0.1s;

        max-width: calc(70% - 250px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

      }

      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
        .arrows {
          margin-right: 16px;
          button:disabled {
            color: rgba(0, 0, 0, 0.1);
          }
          button:first-child {
            margin-right: 6px;
          }
          svg {
            font-size: 20px;
          }
        }
      }
    }
  }
}
