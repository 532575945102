.ListItem {
  border-radius: 13px;
  height: 28px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
  margin-right: 3px;
  color: #444;
  cursor: pointer;
  // background-color: white;
  // transition: background-color 0.1s, color 0.1s;
  span {
    vertical-align: middle;
    padding: 0;
    padding-left: 14px;
    height: 100%;
    width: 100%;
    line-height: 28px;
    svg {
      font-size: 20px;
      height: 100%;
      float: right;
      margin-right: 4px;
    }
  }
  @media (hover: hover) {
    &:hover {
      background-color: #f0f0f0;
    }
  }
}
